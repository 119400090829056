// export default function extractDomain(url) {
import { getElementById } from './getElementById';
//   const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/g;
//   const matches = url.match(domainRegex);
//   if (matches && matches.length > 0) {
//     return matches[0];
//   }
//   return null;
// }
export default function extractDomain(url) {
  const domainRegex =
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+(:\d+)?)/g;
  const matches = url.match(domainRegex);

  if (matches && matches.length > 0) {
    return matches[0];
  }

  return null;
}
