export default function getFirstSiblingWithClass(element, className) {
  // Get the parent element of the provided element
  const parent = element.parentElement;

  if (!parent) {
    // If there is no parent, return null
    return null;
  }

  // Find all siblings of the parent with the specified class name
  const siblings = Array.from(parent.children).filter((sibling) =>
    sibling.classList.contains(className),
  );

  if (siblings.length > 0) {
    // If there are siblings with the specified class, return the first one
    return siblings[0];
  } else {
    // If there are no siblings with the specified class, return null
    return null;
  }
}
