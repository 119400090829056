export function appendToParent(parent, child) {
  return parent.appendChild(child);
}

export function prependChild(parent, child) {
  if (parent.firstChild) {
    // If the parent already has a first child, insert the new child before it
    parent.insertBefore(child, parent.firstChild);
  } else {
    // If the parent has no children, simply append the new child
    parent.appendChild(child);
  }
}
